.MuiBackdrop-root{
    background-color: rgba(29, 43, 79, 0.9);
}
.modal-title{
    margin-bottom: 3.5rem;
    margin-top: 1rem;
    font-family: var(--font-Base-Bold);
}
.mb-20{
    margin-bottom: 2rem;
}
