*,
*::after,
*::before,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --verde: #86d403;
  --azul-oscuro: #1d2b4f;
  --azul-claro: #02bcff;
  --gris-claro: #f2f7fd;
  --negro-claro: #10384f;
  --sky-blue: #338cbf;
  --morning-blue: #81c1ea;
  --yellow: #fcda47;
  --summer-yellow: #ffbe12;
  --light-blue: #f4f6f9;
  --shadow-blue: #dae0e9;
  --red: #f3685f;
  --green: #39c6ac;
  --white: #ffffff;
  --gray: #636363;
  --light-gray: #aaaaaa;
  --dark-blue: #1d2b4f;
  --green-primary: #86d403;
  --gray-primary: #808eb0;
  --gray-terciary: #ecf3fe;
  --black: #000000;
  --gray-secondary: #a6b1cf;
  --gray-third: #7783a3;
  --red-secondary: #fe3162;
  --white-secondary: #e5e5e5;
  --gray-fourty: #a0aec0;
  --gris: #f4f5fa;
  --gray-text: #8892ad;
  --gray-btn: #c7d9ef;
  --gray-line: #d9e0e9;
  --gray-missions: #2d3748;
  --disabled-primary: #999999;
  --disabled-secondary: #cccccc;
  --disabled-terciary: #666666;
  --blue-card: #11374f;
  --background-secondary: var(--gris);
  --background-primary: var(--azul-oscuro);
  --font-Base: 'CircularStdFont', sans-serif;
  --font-Base-Bold: 'CircularStdBold', sans-serif;
  --font-Base-Book: "CircularBook, sans-serif";
  --font-Base-Medium: "CircularMedium, sans-serif";
  
}

@font-face {
  font-family: 'CircularStdFont';
  src: url('./Assets/Fonts/CircularStd-Light.otf');
}
@font-face {
  font-family: 'CircularMedium';
  src: url('./Assets/Fonts/CircularStd-Medium.otf');
}
@font-face {
  font-family: 'CircularBook';
  src: url('./Assets/Fonts/CircularStd-Book.otf');
}
@font-face {
  font-family: 'CircularStdBold';
  src: url('./Assets/Fonts/CircularStdBold.otf');
}

html {
  font-size: 10px;
  font-family: 'CircularStdFont', Courier, monospace;
  color: var(--gray-primary);
}

.spinner {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
