.blue{
    background-color: var(--dark-blue) !important;
}
.MuiTableRow-root{
    border-radius: 1.4rem!important;
    color: var(--dark-blue)!important;
    margin-bottom: .5rem;
}
.cell-contact{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
}
.data-contact{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  /* justify-content: center; */
  align-items: center;
}
