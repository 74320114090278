
.swiper {
    height: 39rem;
}

.swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 3.4rem;
    border-radius: 1rem;
}

.swiper-pagination{
    bottom: 1rem;
}
.swiper-pagination-bullet{
    background: #ECF3FE;
}
.swiper-pagination-bullet-active{
    background: var(--green-primary);
}
.swiper-button-prev{
  background-color: var(--verde);
  color: var(--white);
  height: 5rem;
  width: 5rem;
  font-size: 2rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
}
.swiper-button-disabled{
  color: var(--gray-text);
  background-color: var( --gray-terciary);
}
.swiper-button-next{
  background-color: var(--verde);
  color: var(--white);
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
}
.swiper-button-disabled{
  color: var(--gray-text);
  background-color: var( --gray-terciary);
}
.swiper-button-next:after{
  font-size: 2rem;
}
.swiper-button-prev:after{
  font-size: 2rem;
}
